import React, {useReducer} from 'react';
import {renderRoutes} from 'react-router-config';
import {useDispatch, useStore} from 'react-redux';
import {object} from 'prop-types';
import {TITLE_POSTFIX} from 'app/seo/seo.constants';
import {Helmet} from 'react-helmet-async';
import {fetchClubMetaData} from 'app/routes/club/ClubPageActions';
import {ClubPageReducer} from 'app/routes/club/ClubPageReducer';
import {TabBar} from 'app/components/tab/TabBar';
import {useParams} from 'react-router';
import {useFetchData, useRestoreData} from 'app/hooks/dataHooks';
import {ErrorHandler} from 'app/components/error/ErrorHandler';

const ClubPage = ({route}) => {
  const {restoredData} = useRestoreData('ClubPage');
  const {clubSlug} = useParams();
  const dispatchRedux = useDispatch();
  const {getState} = useStore();

  const initialState = restoredData ?? {};
  const [state, dispatch] = useReducer(ClubPageReducer, initialState);

  const loadData = () => fetchClubMetaData(clubSlug, dispatchRedux, getState)(dispatch, state);

  useFetchData(state, loadData, 'ClubPage', true);

  const defaultTitle = state.name;
  const districtName = state.district?.name ?? '';
  const titleTemplate = `%s${defaultTitle}${TITLE_POSTFIX}`;

  const TabNames = [
    {
      name: 'News',
      url: `/club/${clubSlug}`,
      hasSubUrl: true,
    },
    {
      name: 'Teams',
      url: `/club/${clubSlug}/teams`,
    },
    {
      name: 'Spiele',
      url: `/club/${clubSlug}/matches`,
    },
    {
      name: 'Steckbrief',
      url: `/club/${clubSlug}/contacts`,
    },
  ];

  return (
    <>
      <Helmet defaultTitle={defaultTitle + TITLE_POSTFIX} titleTemplate={titleTemplate} />
      <TabBar tabs={TabNames} />
      <ErrorHandler subroute={true}>
        {renderRoutes(route.routes, {club: state, defaultTitle, districtName, dispatchRedux})}
      </ErrorHandler>
    </>
  );
};

ClubPage.propTypes = {
  route: object.isRequired,
};

export default ClubPage;
