import React, {useEffect, useReducer} from 'react';
import {useDispatch, useStore} from 'react-redux';
import {renderRoutes} from 'react-router-config';
import {object} from 'prop-types';
import {TITLE_POSTFIX} from 'app/seo/seo.constants';
import {Helmet} from 'react-helmet-async';
import {fetchLeagueMetaData} from 'app/routes/league/LeaguePageActions';
import {LeaguePageReducer} from 'app/routes/league/LeaguePageReducer';
import {TabBar} from 'app/components/tab/TabBar';
import {useParams} from 'react-router';
import {useFetchData, useRestoreData} from 'app/hooks/dataHooks';
import {COMPETITION_IMAGE_FALLBACK} from 'app/helpers/helpers';
import {createCompetitionPathFromParams, generateBasicCompetitionLink} from 'app/helpers/actionsHelpers';
import {ErrorHandler} from 'app/components/error/ErrorHandler';
import encodeHTML from 'app/helpers/encodeHTMLInStrings';
import {updateLastCompetitions} from 'app/helpers/lastItemsHelpers';

const getSchemaMarkup = league => {
  if (!league.slug) {
    return;
  }
  const schema = {
    '@context': 'https://schema.org',
    '@type': 'EventSeries',
    name: league.name,
    location: league.district.name,
    url: 'https://www.fupa.net/league/' + league.slug,
  };

  const image =
    league?.image?.path && !league.image.path.includes(COMPETITION_IMAGE_FALLBACK)
      ? {
          image: {
            '@type': 'ImageObject',
            url: league.image.path + '192x192.png',
            width: 192,
            height: 192,
          },
        }
      : {};

  return encodeHTML(JSON.stringify({...schema, ...image}));
};

const LeaguePage = ({route}) => {
  const {restoredData} = useRestoreData('LeaguePage');
  const {leagueSlug, seasonSlug} = useParams();
  const initialState = restoredData ?? {};
  const [state, dispatch] = useReducer(LeaguePageReducer, initialState);
  const dispatchRedux = useDispatch();
  const {getState} = useStore();

  const loadData = () => fetchLeagueMetaData(leagueSlug, seasonSlug, dispatchRedux, getState)(dispatch, state);
  useFetchData(state, loadData, 'LeaguePage', true);
  const defaultTitle = state.season && seasonSlug ? `${state.name} ${state.season.name}` : state.name;
  const districtName = state.district?.name ?? '';

  useEffect(() => {
    if (state.slug && state.name && state.slug === leagueSlug && state.active) {
      const competitionData = {
        slug: state.slug,
        name: state.middleName,
        image: state.image,
        isLeague: true,
      };
      updateLastCompetitions(competitionData);
    }
  }, [state.slug, state.name, state.slug, state.active, leagueSlug]);

  let baseUrl = Object.keys(state).length
    ? generateBasicCompetitionLink(state)
    : createCompetitionPathFromParams('league', leagueSlug, route.season ? seasonSlug : null);
  const TabNames = [
    {
      name: 'News',
      url: baseUrl,
      hasSubUrl: true,
    },
    {
      name: 'Spiele',
      url: `${baseUrl}/matches`,
    },
    {
      name: 'Tabelle',
      url: `${baseUrl}/standing`,
      hasSubUrl: true,
    },
    {
      name: 'Rankings',
      url: `${baseUrl}/rankings`,
    },
    {
      name: 'Chronik',
      url: `/league/${leagueSlug}/history`,
    },
  ];

  const leagueActive = state.active;
  const robots = leagueActive ? 'index, follow' : 'noindex, follow';
  const meta = [
    {
      name: 'robots',
      content: robots,
    },
  ];

  const schema = getSchemaMarkup(state);

  return (
    <>
      <Helmet
        defaultTitle={defaultTitle + ' - ' + districtName + TITLE_POSTFIX}
        titleTemplate={`%s${defaultTitle} - ${districtName}${TITLE_POSTFIX}`}
        meta={meta}>
        <script type='application/ld+json'>{schema}</script>
      </Helmet>
      <TabBar tabs={TabNames} />
      <ErrorHandler subroute={true}>
        {renderRoutes(route.routes, {
          defaultTitle,
          districtName,
          leagueActive,
          leagueId: state.id,
          hasLeagueData: !!state.id,
          competitionSeasonId: state.competitionSeasonId,
          standingFormUpdatedAt: state.standingFormUpdatedAt,
          baseUrl,
          dispatchRedux,
        })}
      </ErrorHandler>
    </>
  );
};

LeaguePage.propTypes = {
  route: object.isRequired,
};

export default LeaguePage;
